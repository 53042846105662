.contact-circle {
  background-color: white;
  border-radius: 8px;
}

.data-container-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.chart-container-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.circlebar {
  background-color: #ffffff;
  gap: 0px;
  border-radius: 8px;
  opacity: 0px;
}

.metdot {
  width: 33px;
  height: 33px;
  background-color: #2d5b30;
  border-radius: 50%;
}

.metdot2 {
  width: 33px;
  height: 33px;
  background-color: #d1dbcf;
  border-radius: 50%;
}

.metdot3 {
  width: 33px;
  height: 33px;
  background-color: #F4F4F4;
  border-radius: 50%;
}

.container-logo {
  background-color: #2d5b30;
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* .container-non{ height: 67vh;}
.container-non .grid_class{ height: 61vh;} */
@media (min-width: 1401px) and (max-width: 5000px) {
  .dashboard_only .grid_class {
    width: 100%;
    height: 82.7%;
  }
}

@media (min-width: 768px) and (max-width: 1085px) {
  .dashboard_only .contact-circle {
    width: 100% !important;
    max-width: 100% !important;
  }
  .dashboard_only .dashboard {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashboard_only .light-bg-L.py-4.px-5.flex.items-center.justify-between.h-\[100\%\] {
    display: block;
  }
  .dashboard_only .progressor .recharts-wrapper {
    width: 220px !important;
  }
  .dashboard_only .progressor .recharts-responsive-container {
    min-width: 330px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .dashboard_only .contact-circle {
    width: 100% !important;
    max-width: 100% !important;
  }
  .dashboard_only .dashboard {
    padding-left: 10px;
    padding-right: 10px;
  }
}
