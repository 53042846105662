.sidebar {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
  box-sizing: "20px 2px #0000000D";
}

.sidebar.collapsed {
  width: 58px;
}

button.collapse-btn.btn-custom {
  margin-top: 20px;
}

.middle_sidebar {
  width: 95%;
  padding-left: 6px !important;
}

.toggle-button {
  display: none;
}

.top-section {
  border-bottom: 1px solid #edf0eb !important;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding: 16px 14px;
}

.top-section-collapsed {
  border-bottom: 1px solid #edf0eb !important;
  margin-bottom: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
}

.option a {
  width: 100% !important;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 6px;
  margin-top: 4px;
  border-radius: 10px;
}

.option a:hover {
  background-color: #d1dbcf;
  color: #2d5b30 !important;
}

.option-link.active {
  background-color: #d1dbcf;
  color: #2d5b30 !important;
}

.setting_only .option-link.active {
  background-color: #d1dbcf !important;
  color: #2d5b30;
}

.option.border-bottom {
  border-bottom: 1px solid #edf0eb !important;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.options-collapsed {
}

.options .collapsed {
  width: 100%;
  margin-left: 0px;
}

.logo-image-small {
  width: 30px;
}

.collapse-btn:focus {
  outline: none;
}

.option-link,
.option-link:visited {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
li.sub-option {
  list-style-type: none;
}
.blog_only {
  padding-bottom: 5px;
  padding-top: 1px;
  border-top: none;
}

.sub-option a {
  line-height: 0px;
  text-decoration: none;
  color: var(--color-tertiary-grey-light, #b4b9c8) !important;
  font-weight: 400;
  line-height: 150%;
  font-size: 15px;
}

.sub-option a span {
  font-size: 21px !important;
  margin-right: 10px;
}

ul.sub-options {
  margin: 0px;
  padding: 0px;
}

svg.svg-inline--fa.fa-chevron-down.icon-chevron-down.rotated {
  text-align: revert;
  margin-left: 58px;
}

.icon-chevron-down.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-out;
  margin-left: 58px !important;
  font-size: 12px;
  color: #787d8c;
}

svg.svg-inline--fa.fa-chevron-down.icon-chevron-down {
  font-size: 12px;
  color: #787d8c;
  margin-left: 58px;
}

.logo-image {
  width: 103px;
  height: 54px;
  object-fit: fill;
}

.toggle-container {
  height: 26px;
  width: 45px;
}

.toggle-container::after {
  height: 20px;
  width: 20px;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .middle_sidebar {
  }

  .sidebar {
    width: 180px;
  }

  .option a {
    padding: 6px;
    margin-top: 0px;
  }

  .sidebar-icons {
    height: 14px;
    width: 14px;
  }
}

@media (min-width: 1500px) and (max-width: 1800px) {
  .option-icon {
    font-size: 20px;
  }

  a.option-link {
    font-size: 14.5px !important;
  }

  .sidebar-icons {
    height: 17px;
    width: 17px;
  }

  .toggle-container {
    height: 22px;
    width: 39px;
  }

  .toggle-container::after {
    height: 17px;
    width: 17px;
  }
}

@media (min-width: 1401px) and (max-width: 1499px) {
  .sidebar-icons {
    height: 18px;
    width: 18px;
  }

  .toggle-container {
    height: 23px;
    width: 39px;
  }

  .toggle-container::after {
    height: 17px;
    width: 17px;
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .sidebar-icons {
    height: 17px;
    width: 17px;
  }

  .toggle-container {
    height: 22px;
    width: 39px;
  }

  .toggle-container::after {
    height: 17px;
    width: 17px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .sidebar {
    display: none;
    width: 180px;
  }

  .sidebar.visible {
    display: block;
  }

  .toggle-button {
    display: inline-block;
  }

  button.toggle-button {
    position: absolute;
    top: 25px;
    left: 11px;
  }

  button.collapse-btn.btn-custom {
    display: none;
  }

  .option a {
    padding: 6px;
    margin-top: 0px;
  }

  .sidebar-icons {
    height: 14px;
    width: 14px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .sidebar {
    display: none;
  }

  .sidebar.visible {
    display: block;
  }

  .toggle-button {
    display: inline-block;
  }

  button.toggle-button {
    position: absolute;
    top: 25px;
    left: 11px;
  }

  button.collapse-btn.btn-custom {
    display: none;
  }

  .sidebar-icons {
    height: 14px;
    width: 14px;
  }
}

.sidebar-main {
  position: relative;
}
