.item.dragging {
  opacity: 0.6;
  border: 1px solid #007bff !important;
}

.item.dragging .detail {
  opacity: 0.8;
  transform: scale(1.02);
  background: #f0f0f0;
}

.smartPlan.dragging {
  opacity: 0.6;
}
