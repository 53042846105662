.lease-info-card {
  border: 1px solid #f4f4f4;
  padding: 16px 20px;
  text-align: center;
  width: 272px;
}

.lease-info-card img {
  padding: 10px;
  border-radius: 4px;
}

.property-history-info {
  border-left: 1px solid #6f6f6f;
}

.property-history-info .card-info {
  padding: 5px 10px;
  border-radius: 4px;
}
.Rpr-border{
  width: 3rem;
  padding: 5px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .lease-info-card {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .lease-info-card {
    width: 100%;
  }
}
.large-date-input {
  font-size: 18px; 
  padding: 10px;
  width: 100%; 
}

.large-date-input::-webkit-calendar-picker-indicator {
  font-size: 18px;
}

