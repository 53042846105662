.tabs-container {
  border-radius: 8px;
  border: 1px solid #f4f4f4;
}

.tab-data {
  box-shadow: 0px 0px 20px 0px #007d880d;
  padding: 8px;
}

.tabs-container .active {
  border-radius: 0px;
}

.tab-data:nth-child(1) {
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid #6f6f6f;
}

.tab-data:last-child {
  border-radius: 0px 8px 8px 0px;
  border-left: 1px solid #6f6f6f;
}
