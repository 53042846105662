.layout {
  background-image: url("../assets/images/trioakBg.png");
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: start;
  justify-content: center;
}

.layout-inner {
  width: 80%;
  height: 65vh;
  border-radius: 16px;
  border: 1px solid #d1dbcf;
  display: flex;
}

.layout-left {
  width: 466px;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px 0px 0px 16px;
}

.layout-left img {
  height: fit-content;
}

.layout-right {
  width: 68%;
  height: 100%;
  border-radius: 0px 16px 16px 0px;
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.baselayout-container {
  display: flex;
}

.sidebar-main {
  /* background-color: #141928; */
  min-height: 100vh;
  position: relative;
  border-width: 0px, 0px, 0px, 0px;
  border-style: solid;
  border-color: #edf0eb;
}

.header-dashboard .main_container {
  min-height: 100vh;
}

.dashboard {
  padding: 12px 20px;
}

.container-fluid {
  max-width: 100%;
}

@media (min-width: 481px) and (max-width: 900px) {
  .dashboard {
    padding-left: 0px;
    padding-right: 0px;
  }

  .layout {
    background-image: none;
    justify-content: start;
    display: block;
  }

  .layout-inner {
    display: block;
    width: 100%;
    padding-top: 100px;
  }

  .layout-left {
    width: 100%;
    height: 100px;
    border-radius: 16px;
  }

  .layout-left img {
    height: 80px;
  }

  .layout-right {
    width: 100%;
    padding: 80px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .dashboard {
    padding-left: 0px;
    padding-right: 0px;
  }

  .layout {
    background-image: none;
    justify-content: start;
    display: block;
  }

  .layout-inner {
    display: block;
    width: 100%;
    padding-top: 100px;
  }

  .layout-left {
    width: 100%;
    height: 100px;
    border-radius: 16px;
  }

  .layout-left img {
    height: 80px;
  }

  .layout-right {
    width: 100%;
    padding: 50px;
  }
}
